import "./App.css";
import { BrowserRouter, useLocation, useRoutes } from "react-router-dom";
import { useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Lenis from "lenis";

// Import your pages
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Upload from "./pages/Upload";

// Define routes
const routes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/projects", element: <Projects /> },
  { path: "/upload", element: <Upload /> },
];

const App: React.FC = () => {
  useEffect(() => {
    const lenis = new Lenis();

    const updateLenis = (time: DOMHighResTimeStamp) => {
      lenis.raf(time);
      requestAnimationFrame(updateLenis);
    };

    requestAnimationFrame(updateLenis);

    const handleResize = () => {
      lenis.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(updateLenis as any);
    };
  }, []);

  return (
    <BrowserRouter>
      <PageTransition />
    </BrowserRouter>
  );
};

// Page Transition Component
const PageTransition: React.FC = () => {
  const location = useLocation(); // Provides current location object
  const element = useRoutes(routes); // Resolves the element for the current route

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname} // Unique key for route changes
        classNames="page"
        timeout={300}
      >
        <div className="page">{element}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
